import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
//general
import advice from "./modules/advice";
import adviceGroup from "./modules/adviceGroup";
import login from "./modules/login";
import theme from "./modules/theme";
import register from "./modules/register";
import farm from "./modules/farm";
import farmCost from "./modules/farmCost";
import farmOperation from "./modules/farmOperation";
import farmTension from "./modules/farmTension";
import farmUser from "./modules/farmUser";
import agentState from "./modules/agentState"
import dashboard from "./modules/dashboard";
import dietPlan from "./modules/dietPlan";
import sentinel from "./modules/sentinel";
import country from "./modules/country";
import Province from "./modules/Province";
import city from "./modules/city";
import productType from "./modules/productType";
import product from "./modules/product";
import banner from "./modules/banner";
import bannerProduct from "./modules/bannerProduct";
import meteorology from "./modules/meteorology";
import meteorologyCondition from "./modules/meteorologyCondition";
import farmerFarmUser from "./modules/farmerFarmUser";
import weather from "./modules/weather";
import geoDataBaseType from "./modules/geoDataBaseType";
import geoDataBase from "./modules/geoDataBase";
import indicator from "./modules/indicator";
import indicatorClient from "./modules/indicatorClient";
import tiket from "./modules/tiket";
import userTypeCost from "./modules/userTypeCost";
import financialUser from "./modules/financialUser";
import helpMove from "./modules/helpMove";
import helpQuestion from "./modules/helpQuestion";
import connectionAgent from "./modules/connectionAgent";
import report from "./modules/report";
import correspondences from "./modules/correspondences";
import connectionFarmerAdvicer from "./modules/connectionFarmerAdvicer";
import connectionFarmerFarmuser from "./modules/connectionFarmerFarmuser";
import expertise from "./modules/expertise";
import tips from "./modules/tips";
import general from "./modules/general";
import farmerColleagues from "./modules/farmerColleagues";
import employerColleagues from "./modules/employerColleagues";


Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        advice,
        adviceGroup,
        login,
        theme,
        register,
        farm,
        farmCost,
        farmOperation,
        farmTension,
        farmUser,
        agentState,
        dashboard,
        dietPlan,
        sentinel,
        country,
        Province,
        city,
        productType,
        product,
        banner,
        bannerProduct,
        meteorology,
        meteorologyCondition,
        farmerFarmUser,
        weather,
        geoDataBaseType,
        geoDataBase,
        indicator,
        indicatorClient,
        tiket,
        userTypeCost,
        financialUser,
        helpMove,
        helpQuestion,
        connectionAgent,
        report,
        correspondences,
        expertise,
        connectionFarmerAdvicer,
        connectionFarmerFarmuser,
        tips,
        general,
        farmerColleagues,
        employerColleagues,
    },
    plugins: [createPersistedState()]
});
