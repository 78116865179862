import {connectionAgentService} from "../../services/connectionAgent.service";
import {dietPlanService} from "../../services/dietPlan.service";
import {adviceService} from "../../services/advice.service";

const state = {
    userType: "",
    userId: "",
    title: "",
    areaString: "",
    coordinationString: "",
    productTypeId: "",
    productId: "",
    cultivationsType: "",
    village: "",
    district: "",
    stateId: "",
    cityId: "",
    verifyOtp: false,
    userName: null,
};

const actions = {
    async Gets({ state }, { farmName, farmerPhone, agentState }) {
        let result = await connectionAgentService.GetAllFarms(farmName, farmerPhone, agentState);
        return result;
    },
    async GetFarmDietPlanByFarmId({ }, { farmId }) {
        let result = await dietPlanService.GetByFarmId(farmId);
        return result;
    },
    async GetFarmAdviceByFarmId({ }, { farmId }) {
        let result = await adviceService.GetByFarmId(farmId);
        return result;
    },
    async GetAgentFarmDetail({ }, { id }) {
        let result = await connectionAgentService.GetAgentFarmDetail(id);
        return result;
    },
    async GetFarmFarmuser({ }, { farmId, searchName }) {
        let result = await connectionAgentService.GetFarmFarmuser(farmId, searchName);
        return result;
    },
    async GetFarmAdvicer({ }, { farmId, searchName }) {
        let result = await connectionAgentService.GetFarmAdvicer(farmId, searchName);
        return result;
    },
    async GetFarmFarmer({ }, { farmId }) {
        let result = await connectionAgentService.GetFarmFarmer(farmId);
        return result;
    },
    async GetAllAgentFarmers({ }, { searchName }) {
        let result = await connectionAgentService.GetAllAgentFarmers(searchName);
        return result.data;
    },
    async GetFarmerAllFarms({ }, { farmerId }) {
        let result = await connectionAgentService.GetFarmerAllFarms(farmerId);
        return result;
    },
    async GetAllAgentLegalEntities({ }, { searchName }) {
        let result = await connectionAgentService.GetAllAgentLegalEntities(searchName);
        return result;
    },
    async GetAllAgentAdvicers({ }, { searchName }) {
        let result = await connectionAgentService.GetAllAgentAdvicers(searchName);
        return result;
    },
    async AdvicerSelectedFarms({ }, { advicerId }) {
        let result = await connectionAgentService.AdvicerSelectedFarms(advicerId);
        return result;
    },
    async GetAllAgentFarmusers({ }, { searchName }) {
        let result = await connectionAgentService.GetAllAgentFarmusers(searchName);
        return result;
    },
    async FarmuserSelectedFarms({ }, { farmuserId }) {
        let result = await connectionAgentService.FarmuserSelectedFarms(farmuserId);
        return result;
    },
};

const mutations = {};

export default {
    namespaced: true,
    farmState: state,
    actions,
    mutations,
};
