import axios from "axios";
import {
  apiUrlSendOtp,
  apiUrlVerifyOtp,
  apiUrlCheckPhoneState,
  apiUrlRegister,
} from "../constants/config";

import "core-js/stable";

async function checkPhoneState(phone, userType) {
  try {
    let data = {
      Phone: phone,
      userType: userType,
    };
    let promise = await axios
      .post(apiUrlCheckPhoneState, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function sendOtp(mobile, userType) {
  try {
    let promise = await axios
      .post(apiUrlSendOtp, { Phone: mobile, UserType: userType }, {})
      .then((res) => {
        if (res.data) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function verifyOtp(mobile, userType, otpCode) {
  try {
    let data = {
      Phone: mobile,
      UserType: userType,
      Code: otpCode,
    };
    let promise = await axios
      .post(apiUrlVerifyOtp, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Register(model) {
  try {
    let data = {
      firstName: model.firstName,
      lastName: model.lastName,
      phone: model.phone,
      password: model.password,
      userType: model.type,
    };
    let promise = await axios
      .post(apiUrlRegister, data, {})
      .then((res) => {
        console.log("resuserInfo", res);
        if (res.data && res.data.succeeded) {
          const userInfo = {
            isLogin: true,
            username: res.data.data.userName,
            fullName: res.data.data.fullName,
            token: res.data.data.jwToken,
            email: res.data.data.email,
            isVerified: res.data.data.isVerified,
            type: res.data.data.roles,
          };
          return userInfo;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}


export const registerService = {
  sendOtp,
  verifyOtp,
  checkPhoneState,
  Register,
};
