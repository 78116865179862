import { ColleaguesService } from "../../services/colleagues.service";
import { ConnectionAdvicerService } from "../../services/connectionAdvicer.service";
import { ConnectionFarmuserService } from "../../services/connectionFarmuser.service"; 
const state = {};

const actions = {
  async GetAllRequest({}, { state, fullName, fromDate, toDate }) {
    let result = await ColleaguesService.AllRequestColleagues(
      state,
      fullName,
      fromDate,
      toDate
    );
    return result;
  },
  async AcceptRequest({}, { requestId }) {
    let result = await ColleaguesService.AcceptRequestColleagues(requestId);
    return result;
  },
  async RejectRequest({}, { requestId }) {
    let result = await ColleaguesService.RejectRequestColleagues(requestId);
    return result;
  },
  async AdvicerAllEmployer({}, { }) {
    let result = await ConnectionAdvicerService.GetAdvicerAllEmployer();
    return result;
  },
  async AdvicerAllEmployerFarm({}, { farmerId }) {
    let result = await ConnectionAdvicerService.GetAdvicerAllEmployerFarm(farmerId);
    return result;
  },
  async AdvicerEndOfCollaboration({}, { farmerId }) {
    let result = await ConnectionAdvicerService.AdvicerEndOfCollaboration(farmerId);
    return result;
  },
  

  async FarmuserAllEmployer({}, { }) {
    let result = await ConnectionFarmuserService.GetFarmuserAllEmployer();
    return result;
  },
  async FarmuserAllEmployerFarm({}, { farmerId }) {
    let result = await ConnectionFarmuserService.GetFarmuserAllEmployerFarm(farmerId);
    return result;
  },
  async FarmuserEndOfCollaboration({}, { farmerId }) {
    let result = await ConnectionFarmuserService.FarmuserEndOfCollaboration(farmerId);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  employerColleaguesState: state,
  actions,
  mutations,
};
