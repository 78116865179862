import axios from "axios";
import {
    apiFarmTensionGetAll,
    apiFarmTensionReport,
    apiFarmTensionGetDeiall,
    apiFarmTensionGetAllByFarmId,
} from "../constants/config"
import "core-js/stable";


async function GetAll(pageNumber, pageSize) {
    try {
        let data = {
            PageNumber: pageNumber,
            PageSize: pageSize,
        };
        let promise = await axios
            .post(apiFarmTensionGetAll, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetAllByFarmId(farmId) {
    try {
        let data = {
            FarmId: farmId,
        };
        let promise = await axios
            .post(apiFarmTensionGetAllByFarmId, data, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetAllWithFilter(farmIds,types, pageNumber, pageSize) {
    try {
        let data = {
            FarmIds: farmIds,
            Types: types,
            PageNumber: pageNumber,
            PageSize: pageSize,
        };
        let promise = await axios
            .post(apiFarmTensionReport, data, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function GetDetail(id) {
    try {
        let promise = await axios.get(
            `${apiFarmTensionGetDeiall}${id}`, {}
        ).then((res) => {
            return res.data.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });

        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
export const farmTensionService = {
    GetAll,
    GetAllWithFilter,
    GetDetail,
    GetAllByFarmId,
};
