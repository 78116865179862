/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import {agentService} from '../../services/agent.service';
import {farmerService} from '../../services/farmer.service';
import {farmUserService} from '../../services/farmUser.service';
import {advicerService} from '../../services/advicer.service';
import {weatherService} from "../../services/weather.service";
import {dashboardService} from "../../services/dashboard.service";
import {ProvinceService} from "../../services/province.service";
import {CityService} from "../../services/city.service";
import {dietPlanService} from '../../services/dietPlan.service';
import {adviceService} from '../../services/advice.service';
import {indicatorClientService} from "../../services/indicatorClient.service";
import {ExpertiseService} from "../../services/expertise.service";
import {farmService} from '../../services/farm.service';
import router from '../../router';
import {setCurrentUser} from '../../utils/index'

const state = {};

const actions = {
    async updateFarmer({ state }, { farmer }) {
        let result = await farmerService.PutFarmer(farmer);
        return result;
    },
    async GetFarmerProfile({ state }, { }) {
        let result = await farmerService.GetFarmerProfile();
        return result;
    },

 
    async GetAllCountry({ }, { pageNumber, pageSize }) {
        let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
        return result;
    },
    async GetStateWithCountry({ }, { countryId }) {
        let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
        return result;
    },
    async GetCityWithState({ }, { stateId }) {
        let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
        return result;
    },


    async updateAgent({ state }, { agent }) {
        let result = await agentService.PutAgent(agent);
        return result;
    },
    async GetAgentProfile({ state }, { }) {
        let result = await agentService.GetAgentProfile();
        return result;
    },


    async updateFarmUser({ state }, { farmUser }) {
        let result = await farmUserService.Put(farmUser);
        return result;
    },
    async GetFarmUserProfile({ state }, { }) {
        let result = await farmUserService.GetProfile();
        return result;
    },
    async GetFarmUserExpertiseProfile({ state }, { }) {
        let result = await farmUserService.GetFarmUserExpertiseProfile();
        return result;
    },
    async SaveFarmUserExpertiseProfile({ }, { expertiseinfo }) {
        let result = await farmUserService.SaveFarmUserExpertiseProfile(expertiseinfo);
        return result;
    },
    async GetFarmUserPartner({ }, { count}) {
        let result = await farmUserService.GetFarmUserPartner(count);
        return result;
    },




    async updateAdvicer({ state }, { advicer }) {
        let result = await advicerService.PutAdvicer(advicer);
        return result;
    },
    async GetAdvicerProfile({ state }, { }) {
        let result = await advicerService.GetAdvicerProfile();
        return result;
    },
    async GetAdvicerExpertiseProfile({ state }, { }) {
        let result = await advicerService.GetAdvicerExpertiseProfile();
        return result;
    },
    async SaveAdvicerExpertiseProfile({ }, { expertiseinfo }) {
        let result = await advicerService.SaveAdvicerExpertiseProfile(expertiseinfo);
        return result;
    },
    async GetAdvicerPartner({ }, { count }) {
        let result = await advicerService.GetAdvicerPartner(count);
        return result;
    },



    async GetAllExpertise({ state }, { }) {
        let result = await ExpertiseService.GetAll();
        return result;
    },



    async GetsFarmerTextValue({ state }, { }) {
        let result = await farmerService.GetTextValueList();
        return result;
    },
 


    async GetMessage({ }, { condition }) {
        let result = await weatherService.GetMessage(condition);
        return result;
    },
    async GetAdminDashboardCount({ }, { }) {
        let result = await dashboardService.GetAdminDashboardCount();
        return result;
    },
    async GetCurrentUserAgentInfo({ }, { }) {
        let result = await dashboardService.GetCurrentUserAgentInfo();
        return result;
    },


    async GetIndicatorDateTimes({ }, { farmId }) {
        let result = await indicatorClientService.GetDateTimes(farmId);
        return result;
    },

    logout({ commit }) {
        commit('resetState');
        setCurrentUser(null);
        router.push('/pages/login').catch(() => { });
    },

    async GetUserInfoWithPhone({ }, { phone, userType }) {
        let result = await dashboardService.GetUserInfoWithPhone(phone, userType);
        return result;
    },
    async GetUserIdentityCode({ }, { userType }) {
        let result = await dashboardService.GetUserIdentityCode(userType);
        return result;
    },
    async SendUserIdentityCode({ }, { phone }) {
        let result = await dashboardService.SendUserIdentityCode(phone);
        return result;
    },

    //
    async GetFarmDietPlanByFarmId({ }, { farmId }) {
        let result = await dietPlanService.GetByFarmId(farmId);
        return result;
    },
    async GetFarmAdviceByFarmId({ }, { farmId }) {
        let result = await adviceService.GetByFarmId(farmId);
        return result;
    },
    async GetAdviceInfoById({ }, { id }) {
        let result = await adviceService.GetAdviceInfoById(id);
        return result;
      },

    //
    async CheckMapImage({ }, { info }) {
        let result = await farmService.CheckMapImage(info);
        return result;
    },

};
const mutations = {
    resetState(state) {
        state.isLogin = false;
        state.user = null;
        state.username = null;
        state.fullName = null;
        state.token = null;
        state.email = null;
        state.isVerified = null;
        state.type = null;
    }
};
export default {
    namespaced: true,
    registerState: state,
    actions,
    mutations
};
