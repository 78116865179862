<template>
  <div>
    <router-view :key="$route.fullPath"></router-view>
    <notifications position="top left" :duration="5000"/>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
export default {
  name: "App",
  // data() {
  // 	return {
  // 		deferredPrompt: null,
  // 	};
  // },
  methods: {
    // async dismiss() {
    // 	this.deferredPrompt = null;
    // 	// this.showBanner = false;
    // },
    // async install() {
    // 	this.deferredPrompt.prompt();
    // 	// this.showBanner = true;
    // },
  },
  // created() {
  // 	window.addEventListener("beforeinstallprompt", (e) => {
  // 		e.preventDefault();
  // 		// Stash the event so it can be triggered later.
  // 		this.deferredPrompt = e;
  // 	});
  // 	window.addEventListener("appinstalled", () => {
  // 		this.deferredPrompt = null;
  // 	});
  // },
  updated() {
    // Find the element with class names "c-active c-sidebar-nav-link"
    const activeElement = document.querySelector(".c-active.c-sidebar-nav-link");

    if (activeElement) {
      // Find the parent element with class name "c-sidebar-nav-dropdown"
      const parentDropdown = activeElement.closest(".c-sidebar-nav-dropdown");
      if (parentDropdown) {
        // Add the class "c-show" to the found parent
        parentDropdown.classList.add("c-show");
      }
    }
  }
};
</script>

<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
// Import Main styles for this application
@import "assets/scss/style";


//vars
:root {
  --cui-border-radius: 8px;
  --border-radius: 8px;
  --cui-btn-padding-y: .8rem;
  --cui-btn-padding-x: .75rem;
}

.c-app {
  --primary: #ea903b !important;
  --secondary: #129B70 !important;
  --primary-light: rgb(234, 162, 95) !important;
  --secondary-light: rgba(18, 155, 112, 0.73) !important;
}

//styles

.btn-primary,.btn-outline-primary:hover {
  color: #fff !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>

<style lang="css">
@font-face {
  font-family: "IRANSansWeb";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Vazir";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Koodak";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Lotus";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Nazanin";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Dirooz";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Gandom";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Yekan";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "Dirooz";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

@font-face {
  font-family: "ShabnamBold";
  src: url(assets/fonts/Shabnam-FD.ttf);
}

* {
  font-family: "IRANSansWeb";
}

@media screen and (min-width: 0px) {
  .row [class*="col"] {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .row [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@-moz-document url-prefix() {
  .map-wrapper{
    height: 91dvh !important;
  }

  @media screen and (min-width: 1280px) {
    .map-wrapper{
      height: 87dvh !important;
    }

  }
  @media screen and (min-width: 1600px) {
    .map-wrapper{
      height: 89dvh !important;
    }
  }
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.c-main {
  padding: 0px !important;
}

.container-fluid {
  padding: 0px !important;
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}


#map {
  position: absolute;
  inset:0;
  z-index: 1;
}

a.mapboxgl-ctrl-logo {
  visibility: hidden;
  display: none !important;
}

.maptooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* maptooltip text */
.maptooltip .maptooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: blanchedalmond;
  color: rgb(37, 36, 36);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the maptooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in maptooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* maptooltip arrow */
.maptooltip .maptooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: blanchedalmond transparent transparent transparent;
}

/* Show the maptooltip text when you mouse over the maptooltip container */
.maptooltip:hover .maptooltiptext {
  visibility: visible;
  opacity: 1;
}

.mapboxgl-ctrl-bottom-left{
  bottom: 15px !important;
  left: 30px !important;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  margin: 0 0 10px 20px !important;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin: 0 20px 20px 0 !important;
}

#chartModal footer.modal-footer {
  display: none !important;
}

@media only screen and (max-width: 761px) {
  /* For mobile phones: */
  .mapboxgl-ctrl-geocoder {
    display: none;
  }
}

/* Vue-Select */
.v-select .vs__actions {
  padding: 0 15px !important;
  gap: 15px;
}
.vs__dropdown-toggle{
  padding: 0 !important;
  border: 1px solid #e4e7ea !important;
}
.v-select, .v-select *{
  box-sizing: border-box;
  line-height: unset !important;
  font-size: unset !important;
  margin: unset !important;
  width: unset !important;
}
.vs__dropdown-menu{
  width: 100% !important;
}
.vs__search{
  border-radius: var(--border-radius) !important;
  padding: var(--cui-btn-padding-y) var(--cui-btn-padding-x) !important;
}
/* DATEPICKER  */

.vpd-icon-btn {
  margin: 0;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  padding:var(--cui-btn-padding-y) var(--cui-btn-padding-x);
  height: unset;
}

/* NOTIFICATION  */
.notification-title::after {
  content: "✕";
  font-family: Arial, serif;
  transition: all .3s;
  cursor: pointer;
  background: #f2f2f2;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-title {
  text-align: right;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vue-notification-wrapper {
  overflow: initial !important;
}

.vue-notification {
  padding: 10px;
  margin: 5px !important;
  font-size: 12px;
  color: #222 !important;
  background: rgb(255 255 255) !important;
  border: none;
  position: relative;
  box-shadow: 0 0 20px 0 #aaaaaa;
  border-radius: var(--border-radius);
}

.vue-notification-group {
  top: 30px !important;
  left: 20px !important;
  bottom: unset !important;
}

.notification-content {
  padding-top: 5px;
  text-align: right;
  overflow: initial;
}

.vue-notification.error {
  border-left: none;
  border-right: 5px solid #b82e24;
}

.vue-notification.success {
  border-left: none;
  border-right: 5px solid #24b838;
}

.vue-notification.info {
  border-left: none;
  border-right: 5px solid #286bcc;
}

/* ANIMATE CSS */
@import "~animate.css/animate.css";
button:focus {
  outline: 0 !important;
}

.animate__delay-400ms {
  animation-delay: 400ms;
}

.animate__delay-550ms {
  animation-delay: 550ms;
}

.animate__delay-700ms {
  animation-delay: 700ms;
}

.animate__delay-850ms {
  animation-delay: 850ms;
}

/*  Vue Confirm CSS OVERRIDES */
.vc-btn.left{
  color: #333;
}
.vc-btn:not(.left){
  color:var(--danger)
}

/*  AG-GRID CSS OVERRIDES */

.ag-header-row {
  background-color: #6e798b5d;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  background-color: #000000 !important;
}

.ag-header-cell-label {
  justify-content: right;
}

.ag-theme-alpine .ag-row-odd {
  background-color: whitesmoke !important;
}
.ag-center-cols-container,.ag-center-cols-viewport,.ag-center-cols-clipper{
  padding: 0!important;
}
.ag-theme-alpine {
  overflow: hidden;
  border-radius: var(--border-radius);
}
.ag-header-row {
  background-color: #0000000d;
}
/*  MULTISELECT CSS OVERRIDES */
.multiselect__content-wrapper{
  z-index: 100000000 !important;
}

/* VUE LOADING CSS OVERRIDES */
.velmld-overlay{
  border-radius: var(--border-radius);
}

/* DATE CSS OVERRIDES */
.fc .fc-button-primary{
  border-radius: var(--border-radius) !important;
}
.fc .fc-button-primary{
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}
.fc .fc-button-group{
  gap: 5px;
}
.fc .fc-scrollgrid-liquid{
  border-radius: var(--border-radius);
  overflow: hidden;
}
/* MODAL CSS OVERRIDES */

.vm {
  top: 50% !important;
  transform: translateY(-50%) !important;
  border-radius: var(--border-radius);
  overflow-y: auto;
}

.vm-backdrop {
  backdrop-filter: blur(5px);
}

.vm.drawer {
  top: 0;
  margin: 0 0 0 auto;
  height: 100%;
  width: 100%;
  max-width: 550px;
}

.vm-btn-close {
  color: #363636 !important;
  background: #fff !important;
  outline: none;
  float: unset !important;
  border-radius: var(--border-radius);
  transition: all .1s;
  transform: scale(1);
}

.vm-btn-close:hover {
  transform: scale(1.1);
}

.vm-titlebar {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vm-content {
  background: #fff;
  box-sizing: unset !important;
  overflow: hidden;
}

/* END */

.deleteCellBtn {
  width: 35px;
  height: 35px;
  color: var(--danger);
  border: none;
  background-color: #ffffff00;
  font-size: 21px;
}

.editCellBtn {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: none;
  color: var(--secondary);
  font-size: 21px;
}

.card-header {
  padding: 0.75rem 1.25rem 0.11rem 1.25rem;
}

div::-webkit-scrollbar {
  height: 8px;
  width: 6px;
}

/* Track */
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #1a3747;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}

/* TEXT COLORS */
.text-white-80 {
  color: rgba(255, 255, 255, 0.8);
}

.text-white-90 {
  color: rgba(255, 255, 255, 0.9);
}

.text-secondary {
  color: var(--secondary);
}

/* ADS */

.ads-wrapper a {
  display: block;
}
.ads-wrapper img {
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgb(186 186 186 / 61%);
}

/* SPACE */
.-m-anti-card{
 margin: -1.25rem;
}
.-mx-15{
 margin-left: -15px;
 margin-right: -15px;
}
.body-h-fix{
  height: 94dvh;
}
/* FOUNDATION */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-g-5 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.vertical-g-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vertical-g-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.y-center-g-5 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.y-center-g-10 {
  display: flex;
  align-items: center;
  gap: 10px;
}


.center-g-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.center-g-10 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.body-wrapper {
  padding: 15px;
}

.d-grid {
  display: grid;
}

.justify-content-evenly{
  justify-content: space-evenly;
}

/* FORM */


/* GAP */
.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-40 {
  gap: 40px;
}

/* POSITION */
.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-center {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.left-center {
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.top-center {
  top: 0;
  right: 50%;
  transform: translateX(-50%);
}

.bottom-center {
  bottom: 0;
  right: 50%;
  transform: translateX(-50%);
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.order-1{
  order:1;
}
.order-2{
  order:2;
}
.order-3{
  order:3;
}
.order-4{
  order:4;
}
/* z-index */

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-50 {
  z-index: 50;
}

/********************** COMPONENTS **************************/
/* DIVIDER */
hr {
  margin: .25rem 0;
  border-top: 1px solid;
  border-color: rgb(0 0 21 / 11%);
}

hr.vertical{
  border: 0;
  border-right: 1px solid rgb(0 0 21 / 11%);
}

/* CARD */
.base-card {
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px 0 rgb(186 186 186 / 61%);
}

.main-card {
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px 0 rgb(186 186 186 / 61%);
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  padding: 10px;
}

.shadow-card {
  box-shadow: 0 0 10px 0 rgb(186 186 186 / 61%);
  border-radius: var(--border-radius);
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
  height: 100%;
}

.blur-card {
  border-radius: var(--border-radius);
  overflow: hidden;
  background: rgb(0 0 0 / 45%);
  backdrop-filter: blur(10px);
}

.auth-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 5px 0 #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;
  gap: 10px;
}

.tab-body-card{
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  box-shadow: 0 0 10px 0 rgb(186 186 186 / 61%);
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  padding: 20px;
}
/* CHIP */
.chip {
  border: none;
  background: #fff;
  border-radius: var(--border-radius);
  padding: 5px 10px;
  color: #4a4a4a;
}

.chip:hover {
  background: var(--primary);
  color: #fff;
}


/* AVATAR */
.dot-online {
  width: 8px;
  height: 8px;
  background: #0ea80e;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 0 0 7px 1px #02e102;
}
.dot-normal {
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
}

/* CURSOR */
.cursor-pointer {
  cursor: pointer;
}


/* lights */

.light-1 {
  position: fixed;
  left: 35%;
  top: 35%;
  background: rgb(244, 138, 6);
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  filter: blur(7rem);
}

.light-2 {
  position: fixed;
  left: 55%;
  top: 55%;
  background: rgb(0, 169, 255);
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  filter: blur(7rem);
}


.overflow-visible{
  overflow: visible !important;
}
</style>


