import axios from "axios";
import {
  apiGetAllRequestColleagues,
  apiGetAllAdvicer,
  apiGetAllFarmuser,
  apiRequestColleagues,
  apiAcceptColleagues,
  apiRejectColleagues,
  apiCancelRequest,
} from "../constants/config";

async function AllRequestColleagues(state, fullName, fromDate, toDate) {
  try {
    let data = {
      State: state,
      FullName: fullName,
      FromDate: fromDate,
      ToDate: toDate,
    };
    let promise = await axios
      .post(apiGetAllRequestColleagues, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function GetAllColleaguesAdvicer(searchText) {
  try {
    let data = {
      SearchText: searchText,
    };
    let promise = await axios
      .post(apiGetAllAdvicer, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function GetAllColleaguesFarmuser(searchText) {
  try {
    let data = {
      SearchText: searchText,
    };
    let promise = await axios
      .post(apiGetAllFarmuser, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function SendRequestColleagues(userId, userType) {
  try {
    let data = {
      UserId: userId,
      UserType: userType,
    };
    let promise = await axios
      .post(apiRequestColleagues, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function AcceptRequestColleagues(requestId) {
  try {
    let data = {
      RequestId: requestId
    };
    let promise = await axios
      .post(apiAcceptColleagues, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function RejectRequestColleagues(requestId) {
  try {
    let data = {
      RequestId: requestId
    };
    let promise = await axios
      .post(apiRejectColleagues, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function CancelRequestColleagues(requestId, userType) {
  try {
    let data = {
      RequestId: requestId,
      UserType: userType,
    };
    let promise = await axios
      .post(apiCancelRequest, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
 
export const ColleaguesService = {
  AllRequestColleagues,
  GetAllColleaguesAdvicer,
  GetAllColleaguesFarmuser,
  SendRequestColleagues,
  AcceptRequestColleagues,
  RejectRequestColleagues,
  CancelRequestColleagues,
};
