import { ColleaguesService } from "../../services/colleagues.service";

const state = {};

const actions = {
  async GetAll({}, { state, fullName, fromDate, toDate }) {
    let result = await ColleaguesService.AllRequestColleagues(state, fullName, fromDate, toDate);
    return result;
  },
  async GetAllAdvicer({}, { searchText }) {
    let result = await ColleaguesService.GetAllColleaguesAdvicer(searchText);
    return result;
  },
  async GetAllFarmuser({}, { searchText }) {
    let result = await ColleaguesService.GetAllColleaguesFarmuser(searchText);
    return result;
  },

  async SendRequest({}, { userId, userType }) {
    let result = await ColleaguesService.SendRequestColleagues(userId, userType);
    return result;
  },

  async CancelRequest({}, { requestId, userType }) {
    let result = await ColleaguesService.CancelRequestColleagues(requestId, userType);
    return result;
  },
  
};
const mutations = {};
export default {
  namespaced: true,
  farmerColleaguesState: state,
  actions,
  mutations,
};
