import Vue from "vue";
import CurrentRouter from "vue-router";
import AuthGuard from "../utils/auth.guard";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

const contactus = () => import("@/views/pages/contactUs");
const aboutus = () => import("@/views/pages/aboutUs");
const support = () => import("@/views/supports/supportsForm");
const product = () => import("@/views/product/productForm");
const userExpertiseForm = () => import("@/views/expertise/userExpertiseForm");

// Views - farm
const MyFarmsList = () => import("@/views/farm/farmList");

//Views - advice
const advice = () => import("@/views/advice/adviceList");

//Views - dietPlan
const dietPlan = () => import("@/views/dietPlan/dietPlanList");

//Views - farm
const farmAddMap = () => import("@/views/farmAdd/farmAddMap");
const farmAddMapDetail = () => import("@/views/farmAdd/farmAddMapDetail");
const farmAddKML = () => import("@/views/farmAdd/farmAddKML");
const farmAddArchive = () => import("@/views/farmAdd/farmAddArchive");

//Views - farmAction
const farmCost = () => import("@/views/farmAction/Cost");
const farmOperation = () => import("@/views/farmAction/Operation");
const farmTension = () => import("@/views/farmAction/Tension");

//Views - Meteorology
const weatherMonitoring = () => import("@/views/weather/Weather");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Policy = () => import("@/views/pages/pprivacy_and_policy");
const IRPolicy = () => import("@/views/pages/ir_pprivacy_and_policy");

const GeoDataBase = () => import("@/views/geoDataBase/geoDataBaseForm");

const AppPaymentCheck = () => import("@/views/pages/payment/AppPaymentCheck");
const AppIndicatorPaymentCheck = () =>
  import("@/views/pages/payment/AppIndicatorPaymentCheck");

//#region financial
const buySentinelService = () =>
  import("@/views/financialUser/buySentinelService/buySentinelService");
const userAccountManagement = () =>
  import("@/views/financialUser/userAccountManagement/userAccountManagement");
const farmShopReport = () =>
  import("@/views/financialUser/farmShopReport/farmShopReport");
const financialUserReports = () =>
  import("@/views/financialUser/financialUserReports/financialUserReports");
const financialDiscount = () =>
  import("@/views/financialUser/financialDiscount/financialDiscount");

const correspondences = () =>
  import("@/views/correspondences/correspondencesForm");
// #endregion

//#region Connections

const cooperationRequest = () =>
  import("@/views/cooperationRequest/cooperationRequestForm");
const employers = () =>
  import("@/views/employers/employersForm");     

//#region Farmer Connections


const conFarmerAdvicer = () =>
  import("@/views/conFarmerAdvicer/farmerAdvicerForm");
const conFarmerFarmuser = () =>
  import("@/views/conFarmerFarmuser/farmerFarmuserForm");
// #endregion

//#region Advicer Connections
const conAdvicerTips = () => import("@/views/tips/advicerTips");
// #endregion

//#region Farmuser Connections
const conFarmuserTips = () => import("@/views/tips/farmuserTips");
// #endregion

//#region Agent Connections
const conAgentFarmer = () => import("@/views/conAgentFarmer/agentFarmerForm");
// #endregion
// #endregion

Vue.use(CurrentRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "صفحه اصلی",
    component: TheContainer,
    meta: { loginRequired: true },
    children: [
      {
        path: "dashboard",
        name: "نقشه های ماهواره ای زمین",
        component: Dashboard,
      },
      {
        path: "product",
        name: "محصولات",
        component: product,
      },
      {
        path: "farms",
        name: "زمین های من",
        component: MyFarmsList,
      },
      {
        path: "farmAddMap",
        name: "ثبت اطلاعات زمین با نقشه",
        component: farmAddMap,
      },
      {
        path: "farmAddMapDetail",
        name: "ثبت جزئیات زمین",
        component: farmAddMapDetail,
      },
      {
        path: "farmAddKML",
        name: "ثبت اطلاعات زمین با فایل",
        component: farmAddKML,
      },
      {
        path: "farmAddArchive",
        name: "ثبت اطلاعات زمین از آرشیو",
        component: farmAddArchive,
      },
      {
        path: "weather",
        name: "هواشناسی",
        component: weatherMonitoring,
      },
      {
        path: "contactus",
        name: "تماس با ما",
        component: contactus,
      },
      {
        path: "aboutUs",
        name: "درباه ما",
        component: aboutus,
      },
      {
        path: "support",
        name: "پشتیبانی",
        component: support,
      },
      {
        path: "cost",
        name: "هزینه ها",
        component: farmCost,
      },
      {
        path: "tension",
        name: "تنش ها",
        component: farmTension,
      },
      {
        path: "operation",
        name: "عملیات ها",
        component: farmOperation,
      },
      {
        path: "advice",
        name: "توصیه های کاربر",
        component: advice,
      },
      {
        path: "advice/:id",
        name: "توصیه های زمین",
        component: advice,
        props: true,
      },
      {
        path: "dietPlan",
        name: "برنامه غذایی کاربر",
        component: dietPlan,
      },
      {
        path: "dietPlan/:id",
        name: "برنامه غذایی زمین",
        component: dietPlan,
        props: true,
      },
      {
        path: "GeoDataBase",
        name: "تأسیسات",
        component: GeoDataBase,
      },
      {
        path: "buySentinelService",
        name: "خرید خدمات ماهواره ای",
        component: buySentinelService,
      },
      {
        path: "userAccountManagement",
        name: "افزایش موجودی کیف پول",
        component: userAccountManagement,
      },
      {
        path: "farmShopReport",
        name: "خدمات خریداری شده",
        component: farmShopReport,
      },
      {
        path: "financialUserReports",
        name: "گزارشات و تراکنش های مالی",
        component: financialUserReports,
      },
      {
        path: "financialDiscount",
        name: "جشنواره ها و کدهای تخفیف",
        component: financialDiscount,
      }, 
      {
        path: "cooperationRequest",
        name: "درخواست همکاری",
        component: cooperationRequest,
      },
      {
        path: "employers",
        name: "کارفرمایان",
        component: employers,
      },
      //Farmer 
      {
        path: "conFarmerAdvicer",
        name: "متخصصان من",
        component: conFarmerAdvicer,
      }, 
      {
        path: "conFarmerFarmuser",
        name: "کارمندان مزرعه من",
        component: conFarmerFarmuser,
      }, 
      //advicer 
      {
        path: "advicerTips",
        name: "دستورالعمل ها",
        component: conAdvicerTips,
      },
      //farmuser 
      {
        path: "farmuserTips",
        name: "دستورالعمل کارمندان",
        component: conFarmuserTips,
      },
      //agent
      {
        path: "conAgentFarmer",
        name: "کشاورزان",
        component: conAgentFarmer,
      },
      {
        path: "correspondences",
        name: "مکاتبات",
        component: correspondences,
      },
      {
        path: "userExpertiseForm",
        name: "پروفایل تخصصی کاربر",
        component: userExpertiseForm,
      },
    ],
  },
  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: Page404,
      },
      {
        path: "500",
        name: "Page500",
        component: Page500,
      },
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "policy",
        name: "Policy",
        component: Policy,
      },
      {
        path: "ir_policy",
        name: "IRPolicy",
        component: IRPolicy,
      }, //
      {
        path: "payment-check",
        name: "PaymentCheck",
        component: AppPaymentCheck,
      },
      {
        path: "payment-indicator-check",
        name: "PaymentIndicatorCheck",
        component: AppIndicatorPaymentCheck,
      },
    ],
  },
];

const router = new CurrentRouter({
  linkActiveClass: "open active",
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
  routes,
});

router.beforeEach(AuthGuard);
export default router;
