import axios from "axios";
import {
  apiDietPlanGetById,
  apiDietPlanGetAllWithUserFarms,
  apiDietPlanGetAll,
  apiDietPlanWithFarmId,
  apiDietPlanInsert,
  apiDietPlanEdit,
  apiDietPlanDelete,
} from "../constants/config";
import "core-js/stable";
async function GetByFarmId(farmId) {
  try {
    let data = {
      farmId: farmId,
    };
    let promise = await axios
      .post(apiDietPlanWithFarmId, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from " + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetAllWithUserFarms() {
  try {
    let promise = await axios
      .post(apiDietPlanGetAllWithUserFarms, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

async function GetAll(searchName) {
  try {
    let data = {
      SearchName: searchName,
    };
    let promise = await axios
      .post(apiDietPlanGetAll, data, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function GetById(id) {
  try {
    let url = `${apiDietPlanGetById}/${id}`;
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

async function Insert(dietPlan) {
  try {
    let promise = await axios
      .post(apiDietPlanInsert, dietPlan, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log("aa", res);
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(dietPlan) {
  try {
    let promise = await axios
      .post(apiDietPlanEdit, dietPlan, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiDietPlanDelete}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

export const dietPlanService = {
  GetAll,
  GetAllWithUserFarms,
  GetById,
  GetByFarmId,
  Insert,
  Edit,
  Delete,
};
