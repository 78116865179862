/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { farmService } from "../../services/farm.service";
import { ProductService } from "../../services/product.service";
import { ProvinceService } from "../../services/province.service";
import { CityService } from "../../services/city.service";

const state = {
  areaString: null,
  userType: "",
  userId: "",
  title: "",
  coordinationString: "",
  productTypeId: "",
  productId: "",
  cultivationsType: "",
  village: "",
  district: "",
  stateId: "",
  cityId: "",
  verifyOtp: false,
  userName: null,
};

const actions = {
  async addFarm({ state }, { farm }) {
    farm.areaString = state.areaString;
    farm.coordinationString = state.coordinationString;

    const detail = {
      irrigationSystem: 0,
      waterIrrigationPeriod: 0,
      waterSource: 0,
      waterQuality: 0,
      soilType: 0,
      waterRight: "",
      waterOrbit: "",
      germinationDate: farm.cultivationsDate,
    };
    farm.detail = detail;

    const farmData = new FormData();
    farmData.append("Title", farm.title);
    farmData.append("CultivationsDate", farm.cultivationsDate);
    farmData.append("ProductTypeId", farm.productTypeId);
    farmData.append("ProductId", farm.productId);
    farmData.append("CultivationsType", farm.cultivationsType);
    farmData.append("StateId", farm.stateId);
    farmData.append("CityId", farm.cityId);
    farmData.append("Village", farm.village);
    farmData.append("District", farm.district);
    farmData.append("UserType", farm.userType);
    farmData.append("CoordinationString", farm.coordinationString);
    farmData.append("AreaString", farm.areaString);
    farmData.append("KMLfile", farm.KMLfile);
    farmData.append("Detail", detail);
    let result = await farmService.PostFarmerFarm(farmData);
    return result;
  },
  async addFarmWithArchive({ state }, { farm }) {
    let result = await farmService.CreateFarmWithArchive(farm);
    return result;
  },

  async updateFarm({ state }, { farm }) {
    const farmData = new FormData();
    farmData.append("Id", farm.id);
    farmData.append("Title", farm.title);
    farmData.append("CultivationsDate", farm.cultivationsDate);
    farmData.append("ProductTypeId", farm.productTypeId);
    farmData.append("ProductId", farm.productId);
    farmData.append("CultivationsType", farm.cultivationsType);
    farmData.append("StateId", farm.stateId);
    farmData.append("CityId", farm.cityId);
    farmData.append("Village", farm.village);
    farmData.append("District", farm.district);
    farmData.append("CoordinationString", farm.coordinationString);
    farmData.append("AreaString", farm.areaString);
    let result = await farmService.Put(farmData);
    return result;
  },
  async updateDetails({ state }, { farm }) {
    if (farm.germinationDate) {
      const dateString = farm.germinationDate + " 00:00"; // '22/11/2017 17:53';
      const changedDate = dateString.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      var date = new Date(changedDate);
      farm.germinationDate = farm.germinationDate ? date.toISOString() : null;
    }

    let result = await farmService.PutDetails(farm);
    return result;
  },
  async updateCultivationsEndDate({ state }, { farmId, datetime }) {
    let result = await farmService.CultivationsEndDate(farmId, datetime);
    return result;
  },

  async Gets({ state }, { farmName, farmerPhone, archive }) {
    let result = await farmService.GetList(farmName, farmerPhone, archive);
    return result;
  },
  async GetsReport({}, { filters }) {
    let result = await farmService.GetReport(filters);
    return result;
  },
  async GetsTextValue({ state }, { farmerType, farmerId }) {
    let result = await farmService.GetTextValueList(farmerType, farmerId);
    return result;
  },
  async Get({ state }, { id }) {
    let result = await farmService.Get(id).catch((err) => console.log(err));
    return result;
  },
  async GetCrdinates({ state }, { id }) {
    let result = await farmService
      .GetCrdinates(id)
      .catch((err) => console.log(err));
    return result;
  },
  setAreaString({ commit }, { areaString, coordinationString }) {
    commit("setAreaString", { areaString, coordinationString });
  },
  setFarmData({ commit }, { farmData }) {
    commit("setFarmData", { farmData });
  },


  async GetAllProductType({}, { pageNumber, pageSize }) {
    let result = await ProductService.GetAllProductType(pageNumber, pageSize);
    return result;
  },
  async GetAllProduct({}, { pageNumber, pageSize }) {
    let result = await ProductService.GetAll(pageNumber, pageSize);
    return result;
  },
  async GetAllCountry({}, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },
  async GetAllProvince({}, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAll(pageNumber, pageSize);
    return result;
  },
  async GetAllCity({}, { pageNumber, pageSize }) {
    let result = await CityService.GetAll(pageNumber, pageSize);
    return result;
  },

  async GetProductWithType({}, { productTypeId }) {
    let result = await ProductService.GetAllWithFilter(
      0,
      0,
      null,
      productTypeId,
      null
    );
    return result;
  },
  async GetProvinceWithCountry({}, { countryId }) {
    let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
    return result;
  },
  async GetCityWithProvince({}, { stateId }) {
    let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
    return result;
  },

  async Delete({}, { id }) {
    let result = await farmService.Delete(id);
    return result;
  },
  async DeleteArchive({}, { id }) {
    let result = await farmService.DeleteArchive(id);
    return result;
  },
};
const mutations = {
  setFarmData(state, { farmData }) {
    state.userId = farmData.userId;
    state.title = farmData.title;
    state.productType = farmData.productType;
    state.productName = farmData.productName;
    state.cultivationsType = farmData.cultivationsType;
    state.address = farmData.address;
    state.cultivationsDate = farmData.cultivationsDate;
    state.state = farmData.state;
    state.city = farmData.city;
    state.district = farmData.district;
  },

  setAreaString(state, { areaString, coordinationString }) {
    state.areaString = areaString;
    state.coordinationString = coordinationString;
  },
};
export default {
  namespaced: true,
  farmState: state,
  actions,
  mutations,
};
