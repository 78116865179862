import axios from "axios";
import {
  apiFarmuserAllEmployer,
  apiFarmuserAllEmployerFarm,
  apiFarmuserEndOfCollaboration,
} from "../constants/config";
import "core-js/stable";


async function GetFarmuserAllEmployer() {
  try {
    let promise = await axios
      .post(apiFarmuserAllEmployer, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from FarmuserExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}


async function GetFarmuserAllEmployerFarm(farmerId) {
  try {
    let model = {
      FarmerId: farmerId,
    };
    let promise = await axios
      .post(apiFarmuserAllEmployerFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from FarmuserExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}


async function AdvicerEndOfCollaboration(farmerId) {
  try {
    let model = {
      FarmerId: farmerId,
    };
    let promise = await axios
      .post(apiFarmuserEndOfCollaboration, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from AdvicerExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
export const ConnectionFarmuserService = {
  GetFarmuserAllEmployer,
  GetFarmuserAllEmployerFarm,
  AdvicerEndOfCollaboration,
};
