import axios from "axios";
import {
  apiAdvicerAllEmployer,
  apiAdvicerAllEmployerFarm,
  apiAdvicerEndOfCollaboration,
} from "../constants/config";
import "core-js/stable";

async function GetAdvicerAllEmployer() {
  try {
    let promise = await axios
      .post(apiAdvicerAllEmployer, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from AdvicerExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function GetAdvicerAllEmployerFarm(farmerId) {
  try {
    let model = {
      FarmerId: farmerId,
    };
    let promise = await axios
      .post(apiAdvicerAllEmployerFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from AdvicerExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function AdvicerEndOfCollaboration(farmerId) {
  try {
    let model = {
      FarmerId: farmerId,
    };
    let promise = await axios
      .post(apiAdvicerEndOfCollaboration, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from AdvicerExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
export const ConnectionAdvicerService = {
  GetAdvicerAllEmployer,
  GetAdvicerAllEmployerFarm,
  AdvicerEndOfCollaboration,
};
