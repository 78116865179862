import axios from "axios";
import { getCurrentUser } from "./utils/index";
import router from "./router";

export default function setup() {
	axios.defaults.timeout = 15000000000;

	// Add a request interceptor
	axios.interceptors.request.use(
		function (config) {
			// Do something before request is sent
			let logedInUser = getCurrentUser();

			if (!logedInUser) return config;

			const isLogin = logedInUser.isLogin;
			const token = logedInUser.token;

			if (isLogin)
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
			return config;
		},
		function (err) {
			// Do something with request error
			return Promise.reject(err);
		}
	);
	///TODO
	// Add a response interceptor
	axios.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data

			if (
				response.data.failed === true ||
				response.data.succeeded === false
			) {
				//theme.dispatch("theme/setDisplayError", {
				//	errorText:
				//		response.data.Message || response.data.message,
				//});

				// alert(response.data.failed);
				// alert(response.data.succeeded);
				// alert(response.data.Data);
			}

			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error

			if (
				error.response &&
				error.response.data &&
				error.response.data.Message
			) {
				if (error.response.status === 401) {
					router.push({ name: "Login" });
					return;
				} else if (error.response.status === 404) {
					router.push({ name: "Page404" });

					return;
				} else {
					
					return;
				}
			}
			return Promise.reject(error);
		}
	);
}


