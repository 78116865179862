import { farmTensionService } from "../../services/farmTension.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await farmTensionService.GetAll(pageNumber, pageSize);
    return result;
  },
  async GetAllByFarmId({}, { farmId }) {
    let result = await farmTensionService.GetAllByFarmId(farmId);
    return result;
  },
  async Filter({}, { farmIds, types, pageNumber, pageSize }) {
    let result = await farmTensionService.GetAllWithFilter(
      farmIds,
      types,
      pageNumber,
      pageSize
    );
    return result;
  },
  async GetDetail({}, { id }) {
    let result = await farmTensionService.GetDetail(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  farmTensionState: state,
  actions,
  mutations,
};
