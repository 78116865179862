/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { ConnectionAdvicerService } from "../../services/connectionAdvicer.service";
import { ConnectionFarmuserService } from "../../services/connectionFarmuser.service";
import { tipsService } from "../../services/tips.service";

const state = {};
const actions = {
  async GetAllEmployer({ state }, { currentUserType }) {
    if (currentUserType === "Advicer") {
      let result = await ConnectionAdvicerService.GetAdvicerAllEmployer();
      return result;
    } else if (currentUserType === "FarmUser") {
      let result = await ConnectionFarmuserService.GetFarmuserAllEmployer();
      return result;
    } else {
      return false;
    }
  },
  async GetAllEmployerFarms({ state }, { farmerId, currentUserType }) {
    if (currentUserType === "Advicer") {
      let result = await ConnectionAdvicerService.GetAdvicerAllEmployerFarm(
        farmerId
      );
      return result;
    } else if (currentUserType === "FarmUser") {
      let result = await ConnectionFarmuserService.GetFarmuserAllEmployerFarm(
        farmerId
      );
      return result;
    } else {
      return false;
    }
  },
  async GetAllTips({ state }, { farmerId , farmId }) {
    let result = await tipsService.GetAllTips(farmerId, farmId);
    return result;
  },


  async GetFarmerAllTips({ state }, { userId, userType }) {
    let result = await tipsService.GetFarmerAllTips(userId, userType);
    return result;
  },




  async GetAllTipsAndReply({ state }, { id }) {
    let result = await tipsService.GetAllTipsAndReply(id);
    return result;
  },
  async GetTips({ state }, { id }) {
    let result = await tipsService.GetTips(id);
    return result;
  },
  async NewTips({ state }, { tips }) {
    let result = await tipsService.NewTips(tips);
    return result;
  },
  async NewTipsReply({ state }, { tipsReply }) {
    let result = await tipsService.NewTipsReply(tipsReply);
    return result;
  },
  async EditTips({ state }, { editTips }) {
    let result = await tipsService.EditTips(editTips);
    return result;
  },
  async DeleteTipsFile({ state }, { id }) {
    let result = await tipsService.DeleteTipsFile(id);
    return result;
  },
  async DeleteReplyFile({ state }, { id }) {
    let result = await tipsService.DeleteReplyFile(id);
    return result;
  },
  async GetLastTips({ state }, { userId, userType }) {
    let result = await tipsService.GetLastTips(userId, userType);
    return result;
  },
};
const mutations = {};

export default {
  namespaced: true,
  tipsState: state,
  actions,
  mutations,
};
